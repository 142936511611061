import * as React from "react"
import FullApp from "../FullApp";
import Layout from "../templates/layout";
import NewBlog from '../components/pages/newBlog';
import { graphql } from 'gatsby';
import SEO from "../components/seo";
import MainTopBg from "../components/backgrounds/others/MainTopBg";


export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <MainTopBg style={{backgroundSize: 'contain', backgroundPosition: 'top'}}>
        <SEO title="Blog"/>
        <NewBlog data={props.data}/>
      </MainTopBg>
    </Layout>
  </FullApp>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        excerpt
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          articleDate
          path
          name
        }
      }
    }
  }
`